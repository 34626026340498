import { inject, Injectable } from '@angular/core';
import { Database, ref, get, child } from '@angular/fire/database';
import { Observable, from } from 'rxjs';
 


@Injectable({
  providedIn: 'root',

})
export class FirebaseChatService {

  db: Database = inject(Database);
  

  constructor() { }


  getRealtimeData(path: string): Observable<any> {

     return new Observable(observer => {
      const dataRef = ref(this.db, path);

      // Escuchar cambios en tiempo real
      import('firebase/database').then(({ onValue, off }) => {
        const unsubscribe = onValue(dataRef, (snapshot) => {
          observer.next(snapshot.val());
        }, (error) => {
          observer.error(error);
        });

        // Cleanup cuando se destruye el observable
        return () => off(dataRef);
      });
    }); 
  }

 
}
