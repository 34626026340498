import { CommonModule } from '@angular/common';
import { ApplicationConfig, Component, importProvidersFrom, inject, OnInit } from '@angular/core';
import { child, Database, get, ref } from '@angular/fire/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { KENDO_BUTTONS } from '@progress/kendo-angular-buttons';
import { KENDO_DROPDOWNS } from '@progress/kendo-angular-dropdowns';
import { KENDO_INDICATORS } from '@progress/kendo-angular-indicators';
import { KENDO_INPUTS } from '@progress/kendo-angular-inputs';
import { ComponentsCoreFormsModule, DirectivesCoreFormsModule } from '@src/app/components/Core/core.saludplus.module';
import { from, Observable } from 'rxjs';
import { FirebaseChatService } from '../Services/FirebaseChat.service';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { environment } from '@src/environments/environment';

 

@Component({
  selector: 'app-Chat',
  templateUrl: './Chat.component.html',
  styleUrls: ['./Chat.component.scss'],
  imports: [
    CommonModule,
    ComponentsCoreFormsModule,
    DirectivesCoreFormsModule,
    FormsModule,
    ReactiveFormsModule,
    KENDO_INPUTS,
    KENDO_BUTTONS,
    KENDO_DROPDOWNS,
    KENDO_INDICATORS
  ],
  providers: [
 
  ]
})
export class ChatComponent implements OnInit {

  isLoading: boolean = false;
  title: string;
  icon: string;

  public route = inject(ActivatedRoute);
  public titleService = inject(Title);

  items: any[] = [];
  firebaseChatService = inject(FirebaseChatService);

  constructor() {
  }

  ngOnInit() {
    this.title = this.route.snapshot.data['title'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.title);

     this.firebaseChatService.getRealtimeData('chats').subscribe((data) => {
       if (data) {
         this.items = Object.values(data); // Convierte los datos a un array
       }
     });
  }



}
